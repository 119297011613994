<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-2xl">
          SMS Templates
        </p>
        <v-card
          flat
        >
          <v-card-text class="pa-3">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="3"
              >
                <v-text-field
                  v-model="filterName"
                  :append-icon="icons.mdiMagnify"
                  label="Nome"
                  hide-details
                  outlined
                  dense
                  @click:append="fechSmsTemplates"
                  @keypress.enter="fechSmsTemplates"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-3">
            <v-btn
              color="primary"
              class="pl-4"
              @click="fechSmsTemplates"
            >
              Pesquisar
              <v-icon
                right
                dark
              >
                {{ icons.mdiFileEdit }}
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="pl-4"
              outlined
              @click="openFormToCreate"
            >
              Novo Registro
              <v-icon
                right
                dark
              >
                {{ icons.mdiTablePlus }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          flat
          class="mt-5"
        >
          <v-data-table
            :headers="headers"
            :items="rows"
            item-key="id"
            class="table-rounded"
            disable-sort
            dense
          >
            <template #[`item.action`]="{item}">
              <v-btn
                color="success"
                dark
                text
                title="Editar"
                x-small
                @click="openFormToEdit(item.id)"
              >
                <v-icon>{{ icons.mdiFileEdit }}</v-icon>
              </v-btn>
            </template>
            <template #[`item.status`]="{item}">
              <v-chip
                class="my-2"
                :color="resolveStatus(item.status).color"
                small
                label
              >
                <span class="white--text font-weight-semibold">
                  {{ resolveStatus(item.status).value }}
                </span>
              </v-chip>
            </template>
            <template #[`item.variables`]="{item}">
              <v-chip
                v-for="(variable, index) in ['NUMERO', ...item.variables]"
                :key="index"
                class="ma-1"
                small
              >
                <span class="white--text font-weight-semibold primary--text">
                  {{ variable }}
                </span>
              </v-chip>
            </template>
            <template #[`item.serviceIds`]="{item}">
              <v-chip
                v-for="(service, index) in item.serviceIds"
                :key="index"
                class="ma-1"
                small
              >
                <span class="white--text font-weight-semibold primary--text">
                  {{ resolveServiceName(service) }}
                </span>
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <validation-observer
      ref="formSmsTemplate"
      autocomplete="off"
      tag="form"
      @keyup.enter="fetchAction"
    >
      <dialog-form
        :status="formDialogStatus"
        :is-update="formIsUpdate"
        :title="'SMS Template'"
        :width="1200"
        @action="fetchAction"
        @remove="removeDialogStatus = true"
        @close="formDialogStatus = !formDialogStatus"
      >
        <v-row>
          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Nome"
                  rules="required|max:100"
                  vid="form.name"
                >
                  <v-text-field
                    v-model="form.name"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    label="Nome"
                    outlined
                    dense
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Mensagem"
                  rules="required"
                  vid="form.message"
                >
                  <v-textarea
                    v-model="form.message"
                    :counter-value="(value) => getMessageLenth(value)"
                    :error-messages="errors"
                    counter="160"
                    hint="Para utilizar uma variável, insira-a entre chaves {}, como no exemplo: {NOME}."
                    persistent-hint
                    label="Mensagem (A variável {NUMERO} é reservada para o número do destinatário)"
                    outlined
                    dense
                  >
                  </v-textarea>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="form.variables"
                  :items="form.variables"
                  disabled
                  chips
                  label="Variáveis utilizadas"
                  multiple
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      color="info"
                      label
                      small
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            class="d-sm-flex justify-center position-relative"
          >
            <div class="phone">
              <div class="speaker"></div>
              <div style="width: 425px; height: 771px">
                <div class="center">
                  <div class="chat">
                    <div
                      id="chat"
                      class="messages"
                    >
                      <div
                        v-if="messageChat"
                        class="message parker"
                      >
                        <!-- eslint-disable vue/no-v-html -->
                        <span v-html="messageChat" />
                        <!--eslint-enable-->
                      </div>
                    </div>
                    <div class="input">
                      <input
                        placeholder="Enviar mensagem..."
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </dialog-form>
    </validation-observer>

    <dialog-remove
      :status="removeDialogStatus"
      :text-to-confirm="form.name"
      @action="fetchActionRemove"
      @close="removeDialogStatus = !removeDialogStatus"
    >
    </dialog-remove>

    <tutorial tutorial-key="TSP1" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiFileEdit, mdiMagnify, mdiTablePlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { smsTemplateType, smsTypes } from '@/utils/enum'
import Tutorial from '@/components/tutorial/index.vue'

export default {
  components: {
    Tutorial,
  },
  setup() {
    const filterName = ref(null)
    const filterType = ref(null)
    const formDialogStatus = ref(false)
    const removeDialogStatus = ref(false)
    const formIsUpdate = ref(false)
    const total = ref(null)
    const messageChat = ref('')
    const form = ref({
      id: ref(null),
      name: ref(''),
      message: ref(''),
      observation: ref(''),
      status: ref(null),
      type: ref(null),
      variables: ref([]),
    })

    const resolveStatus = status => {
      if (status === 1) return { color: 'info', value: 'Aguardando Análise' }
      if (status === 2) return { color: 'success', value: 'Aprovado' }

      return { color: 'error', value: 'Recusado' }
    }

    const resolveServiceName = serviceId => {
      const service = smsTypes.find(smsType => smsType.serviceId === serviceId)

      if (service) {
        return service.text
      }

      return null
    }

    return {
      total,
      filterName,
      filterType,
      form,
      formDialogStatus,
      removeDialogStatus,
      formIsUpdate,
      messageChat,
      resolveStatus,
      resolveServiceName,
      icons: {
        mdiFileEdit,
        mdiMagnify,
        mdiTablePlus,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'ID', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'Variáveis', value: 'variables' },
        { text: 'Serviços Associados', value: 'serviceIds' },
      ],
    }
  },

  computed: {
    ...mapState('smsTemplates', [
      'rows',
    ]),
  },

  watch: {
    // eslint-disable-next-line func-names
    'form.message': function (value) {
      const text = value.replace(/(?:\r\n|\r|\n)/g, '<br>')

      this.messageChat = this.$utils.replaceSpecialCharacters(text)
      this.form.variables = this.getVariables(value)
      this.form.message = this.fixTemplateContent(value)
    },
  },

  async created() {
    await this.fechSmsTemplates()
  },

  methods: {
    ...mapActions('smsTemplates', [
      'find',
      'findById',
      'create',
      'update',
      'remove',
    ]),

    getMessageLenth(value) {
      return String(value.replace(/(?:\r\n|\r|\n)/g, '\n\n')).length
    },

    getVariables(text) {
      const regex = /\{([^}]+)\}/g

      return String(text).match(regex)?.map(v => v.slice(1, -1).toUpperCase()) || []
    },

    fixTemplateContent(text) {
      const regex = /\{([^}]+)\}/g

      return String(text).replace(regex, (_, p1) => `{${p1.toUpperCase()}}`)
    },

    async openFormToCreate() {
      this.formDialogStatus = true
      this.formIsUpdate = false
      this.messageChat = ''
      this.form = {
        id: null,
        name: null,
        observation: '',
        message: '',
        status: null,
        type: smsTemplateType.Default,
        variables: [],
      }
    },

    async openFormToEdit(rowId) {
      const smsTemplate = await this.findById(rowId)

      if (smsTemplate) {
        await this.$refs.formSmsTemplate.reset()
        this.formDialogStatus = true
        this.formIsUpdate = true
        this.form = {
          id: smsTemplate.id,
          name: smsTemplate.name,
          message: smsTemplate.message,
          observation: '',
          status: smsTemplate.status,
          type: Number(smsTemplate.type),
          variables: smsTemplate.variables,
        }
      }
    },

    async fechSmsTemplates() {
      await this.find({
        name: this.filterName,
        type: this.filterType,
      })
    },

    async fetchAction(isUpdate) {
      const body = {
        name: this.form.name,
        message: this.$utils.replaceSpecialCharacters(this.form.message),
        status: this.form.status,
        type: Number(this.form.type),
        variables: this.form.variables,
      }

      if (this.getMessageLenth(body.message) > 160) {
        this.$notify(
          'O campo mensagem pode conter até 160 caracteres.',
          'error',
        )

        return
      }

      if (await this.$refs.formSmsTemplate.validate()) {
        if (isUpdate) await this.update(body)
        else await this.create(body)

        this.fechSmsTemplates()
        this.formDialogStatus = false
      }
    },

    async fetchActionRemove() {
      try {
        if (this.form.id) {
          await this.remove(this.form.id)
        }
      } finally {
        this.fechSmsTemplates()
        this.formDialogStatus = false
        this.removeDialogStatus = false
      }
    },
  },
}

</script>
